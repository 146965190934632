import React from "react"
import PropTypes from "prop-types"
import kebabCase from "lodash/kebabCase"
import { Link, graphql } from "gatsby"
import Seo from "../../components/seo";
import BaseLayout from "../../components/layout"
import HorizontalRule from "../../components/horizontal-rule";
import BreadcrumbTrail from "../../components/breadcrumb-trail";

const TagsPage = ({
  data: {
    allMdx: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => {
  let breadcrumbList = [
    [
      {
        title: 'Home',
        url: '/'
      },
      {
        title: 'Library',
        url: '/writing/library/'
      },
      {
        title: 'Genres',
      }
    ]
  ]

  return (
    <BaseLayout>
      <Seo title="Writing · Genres" desc="Search through the list of genres Zack has published stories under." pathname={"/writing/genres/"} breadcrumbList={breadcrumbList}></Seo>
      <div className="layout-base-w app-p-base">
        <BreadcrumbTrail breadcrumbTrail={breadcrumbList[0]}></BreadcrumbTrail>
        <div className="mb-16 md:mb-28">
          <div className="text-mint">
            <HorizontalRule ruleType="1"></HorizontalRule>
            <h1 className="text-header-1 w-full">Genres</h1>
          </div>
        </div>
        <div className="flex flex-wrap">
          {group.map(tag => (
            <h2 className="text-header-2 text-mint mr-10 mb-6" key={tag.fieldValue}>
              <Link className="text-link" to={`/writing/genres/${kebabCase(tag.fieldValue)}`}>{tag.fieldValue}</Link>
              <sup className="ml-1">{tag.totalCount}</sup>
            </h2>
          ))}
        </div>
      </div>
    </BaseLayout>
  )
}

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default TagsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      filter: { frontmatter: { published: { eq: true }, record_type: { eq: "story" } } }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`